import React from 'react'
import { Breadcrumb } from '../components/breadcrumb'
import Layout from '../components/layout'
import Seo from '../components/seo'


const Contact = () => {
  const infoSection = (
    <div className="container">
      <div className="row animated fadeInUp delay1">
        <div className="col-md-5">
          <h1>Kontakt</h1>
        </div>
      </div>
    </div>
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="Kontakt" />
      <Breadcrumb crumbs={[{ label: 'Kontakt' }]} />
      <section className="content_info">
        <div className="paddings border-top">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-6">
                <aside>
                  <h4>
                    Moinsen {'{'}Hamburg{'}'}
                  </h4>
                  <address>
                    <strong>
                      Ulrich Diedrichsen
                    </strong>
                    <br />
                    <i className="fa fa-plane"></i>
                    <strong>Stadt: </strong>20144 Hamburg
                  </address>

                  <address>
                    <strong>Moinsen Emails</strong>
                    <br />
                    <i className="fa fa-envelope"></i>
                    <strong>Email:</strong>
                    <a href="mailto:business@moinsen.dev">
                      business@moinsen.dev
                    </a>
                    <br />
                  </address>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
